import { base } from 'grommet';
import { mergeDeepLeft, path } from 'ramda';

const theme = {
  global: {
    font: {
      family: 'Montserrat',
      size: '15px',
      height: '24px',
    },
    colors: {
      brand: '#00adb5',
      'brand-dark-blue': '#222831',
      'brand-gray': '#393e46',
      'brand-light-gray': '#eeeeee',
      text: {
        dark: '#eeeeee',
        light: '#222831',
      },
    },
  },
  header: {
    height: '60px',
  },
};

const mergedTheme = mergeDeepLeft(theme, base);

export const getThemeColor = colorName =>
  path(['global', 'colors', colorName], mergedTheme);

export default mergedTheme;
