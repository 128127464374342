/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import { Grommet } from 'grommet';
import theme from './src/theme';
import './src/global.css';

export const wrapRootElement = ({ element }) => {
  return (
    <Grommet theme={theme} themeMode="light">
      {element}
    </Grommet>
  );
};

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This web has been updated. Reload to display the latest version?`
  );
  if (answer === true) {
    window.location.reload();
  }
};

const trustAllScripts = () => {
  var scriptNodes = document.querySelectorAll('.load-external-scripts script');

  for (var i = 0; i < scriptNodes.length; i += 1) {
    var node = scriptNodes[i];
    var s = document.createElement('script');
    s.type = node.type || 'text/javascript';

    if (node.attributes.src) {
      s.src = node.attributes.src.value;
    } else {
      s.innerHTML = node.innerHTML;
    }

    document.getElementsByTagName('head')[0].appendChild(s);
  }
};

export const onRouteUpdate = () => {
  window && window.scrollTo({ top: 0, behavior: 'smooth' });
  trustAllScripts();
};
